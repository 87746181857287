import styled from "styled-components";

export interface IBoxProps {
  center?: boolean;
  align?: string;
  width?: any;
}

export default styled.div`
  flex: 0 0 100%;
  padding: 1em;
  text-align: ${(props: IBoxProps) =>
    props.center ? "center" : props.align ? props.align : "left"};
  @media (min-width: 40em) {
    flex: ${(props: IBoxProps) => (props.width ? `0 0 ${props.width}` : 1)};
    width: ${(props: IBoxProps) => (props.width ? props.width : "100%")};
  }
`;
