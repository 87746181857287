import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Two({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1080}
            height={1706.667}
            viewBox="0 0 810 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M344.5 39.7c-60.3 4.9-104.3 15.6-147.1 35.8-58.1 27.4-104.1 69.8-128.9 118.8-14.6 28.9-21.9 58.8-23.2 94.2C43.4 342.4 57.7 383.8 89 415c19.4 19.4 41.2 31.1 69.3 37.2 13.8 3 48.4 3.3 61.9.5 26.3-5.3 48.6-17 66.6-34.8 22.7-22.5 34-46.2 37.3-78.2 4.3-42.6-12.5-82.9-43-103.1-3.6-2.4-19.8-10.3-36-17.6-16.2-7.2-30.6-13.9-32-14.8-4.1-2.5-8.1-6.5-9.7-9.4-4.5-8.7 3.1-23.6 18.4-35.7 25.9-20.7 61.4-32.1 99.4-32.1 85 .1 136.7 46.7 151.7 136.7 3.6 21.4 4.5 34.3 4.5 62.3-.1 50.3-6.8 91.2-22.9 139.5-16.4 49.2-36.1 86.3-71.6 134.5-28.4 38.5-57 73.1-105.5 127.6C196 819 169.8 850.2 142.9 888.1 71.4 988.6 38.6 1078.7 32.4 1191.3l-.7 12.8 2.8 3 2.9 2.9h683.3l.6-3.3c.2-1.7 10.7-77.1 23.3-167.4L767.4 875l-2.9-3-2.9-3H684l-.4 2.2c-.3 1.3-1.5 8.4-2.7 15.8-4.2 26.4-11.5 50.4-19.6 65-11.8 21.1-33.4 30-79.8 33-8.6.6-90.7 1-193.4 1H209.8l.7-2.8c2.2-9.1 16.9-38.3 28.7-57.2 25-39.9 66.6-84.4 114.9-123.1 29.8-23.9 52.1-39.4 96.4-66.9 82.6-51.2 117.6-75 158.1-107.4 56.7-45.2 88.4-81.9 112.3-129.6 24.5-49.1 35.4-100.7 33.8-161-.6-23.9-2.1-37.9-6.3-57.9-17.4-84.4-72.1-153.2-155.9-196.2C544.1 59 489.4 44.7 424.5 40c-12.6-.9-70-1.2-80-.3z" />
        </svg>
    )
}
