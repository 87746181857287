import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Three({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1089.333}
            height={1706.667}
            viewBox="0 0 817 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M360 42.6c-22.1 1.5-36.2 2.7-46.5 4-72.9 9.1-132.1 31.5-178 67.5-11.9 9.3-31.7 29.2-39.6 39.9-17 22.9-27.5 47.2-33.1 77-1.9 9.6-2.2 15.2-2.3 34 0 19.4.3 24 2.2 33.5 4 19.5 10.3 35.5 19.6 49.9 21.7 33.2 54.8 53.5 96 58.6 12.5 1.6 40.3.7 50.7-1.5 25.1-5.5 44.8-16.2 63.3-34.3 19.9-19.5 31.1-43.2 33.9-71.4 4.4-45.4-16-87.9-61.2-127.3-12.3-10.7-15-14.1-14.2-18 1.3-7.2 17-17.9 34.2-23.5 21.3-6.9 53-7.7 79-2 12.4 2.6 20.1 5.4 32.5 11.5 40.1 19.8 70.8 62.7 85.5 119.4 13.4 51.5 15.4 123.8 4.9 176.6-8.4 42.3-24.9 76.5-48.3 100.1-15.8 16-31 24.8-51.5 30.1-13 3.3-26.3 3.9-51.4 2.2-11.7-.8-28.9-1.4-38.2-1.4h-17l-6.7 3.3c-7.7 3.8-17.1 12.7-21.1 20-12.4 22.8-3.6 50 19.8 61.2 12.7 6.1 28.4 7.3 59 4.5 20.6-1.9 30.3-1.9 43.3.1 26.8 4 47.6 14.5 66.8 33.8 26.1 26.4 43.8 66.8 52.9 121 3.6 21.7 5.3 39.5 6.7 69.3 2.4 52.9-2.6 100.2-14.3 136.9-21.8 68.1-64.4 110.3-123.7 122.3-22.2 4.6-50.5 5.2-71.2 1.6-30.9-5.4-61.8-23-66.2-37.8l-1.2-3.9 5-4.7c2.8-2.5 10.5-9.2 17.2-14.9 29.9-25.3 48.4-54.2 55.3-86.2 2.9-13.7 3.2-38.9.6-52.3-8-40.4-34.3-71.8-73.7-88-19.2-7.9-37.8-11.1-64.5-11.1-21.5 0-30.5 1.2-47.2 6-34.5 9.9-67.2 37.5-83.9 70.7-13.5 26.8-18.1 62.3-12.7 98.2 8.5 57.3 42.1 106.3 100.5 146.8 61.4 42.6 138.6 65.6 229.3 68.6 145.1 4.7 261.4-32.9 338-109.4 42.3-42.2 66.2-89.7 75.6-150.5 2-12.5 2.3-18.5 2.3-44 .1-31.2-.6-39.9-5-65-22-123.2-112.1-203.2-273.9-243.1-11-2.7-20.8-4.9-21.7-4.9-4.2 0-1.2-1.7 7-3.9 4.8-1.3 19-5.7 31.6-9.8 117.4-38.2 188.1-92.4 220.1-168.8 12.5-29.8 17.4-55.4 18.2-94.3.7-36.9-1.5-58.4-9.3-88.1-4.7-18.2-9.1-29.7-18.4-48.6-16.2-32.8-38.4-60.4-67.6-83.9-52.6-42.3-118-66.2-202.9-74.2-15-1.4-72.4-2.6-84.5-1.8z" />
        </svg>
    )
}