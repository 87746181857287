import React from 'react';
import { AvatarV } from '../widgets';
import config from '../config/default.blueprint.json';

const bp = config.Avatar?.compProps;

export const Avatar = () => {
    return (
        <AvatarV
            {...{ ...bp }}
        />
    )
}