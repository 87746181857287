import { useMutation } from '@apollo/react-hooks';
import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useEffect, useState } from 'react';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import config from '../../config/default.config.json';

const console: LoggerService = new LoggerService(
  new ConsoleLogger('TermsPage:container'),
);


export const Privacy = () => {

const _renderBody = () => {
//   if(loading){
//     return <LogoSpinnerV uri={'https://i.ibb.co/fkhtppW/400x400-white.png'} />
//   }

    return <PrivacyPolicy />
  }    
  return <>{_renderBody()}</>;
};
