export const fontFamily: any = `-apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetical Neue', sans-serif;`;

export const headingSizes: any = [
  "2rem",
  "1.5rem",
  "1.25rem",
  "1rem",
  ".875rem",
  ".75rem"
];

export const themeColors: any = {
  dark: "#1A4677",
  light: "#5987AF",
  black: "#2d2323"
};
