import React, { useContext } from 'react';
import { QRCodeGeneratorV } from 'react-native-qr-code-generator-vfw';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { View, Text, StyleSheet } from 'react-native';
import { ThemeContext } from '../../../providers/theme/Theme';
import Panel from 'rsuite/lib/Panel';
import PanelGroup from 'rsuite/lib/PanelGroup';
import { AvatarV } from 'react-native-avatar-vfw';
import { useHistory } from 'react-router-dom';
import config from '../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';
import { Icon } from 'react-native-elements';
import Divider from 'rsuite/lib/Divider';
import { NavV } from 'react-nav-rs';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { fillTemplate } from '../../../utils/fillTemplate';

const BP = config?.lg;

export interface IProps {
    qrValue: string;
    logo?: string;
    nop: string;
    type?: string;
    redirect_uri?: string;
}
export const QRCodeView = (props: IProps) => {
    const theme = useContext(ThemeContext);
    const history = useHistory();
    const { bp, width } = useViewport();
    const styles = StyleSheet.create({
        paperStyle: {
            backgroundColor: '#fff'
        },
        containerStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'stretch'
        },
        qrContainerStyle: {
            // backgroundColor:theme?.palette?.ternary,
            // backgroundColor:'#96767f',
            padding: 20
        },
        rightContainerStyle: {
            width: (bp === 'sm') ? width * 0.30 : width * 0.22,
            marginTop: 55,
        },
        qrStyle: {
            backgroundColor: '#fff',
            padding: 10,
            borderWidth: 4,
            borderColor: theme?.palette?.ternary,
            borderRadius: 5,
        },
        titleStyle: {
            // width:(bp==='sm')? 200:300,
            fontSize: 20,
            // lineSpacing: 2,
            textAlign: 'center',
            color: theme?.palette?.primaryDark,
            paddingBottom: 15
        },
        orgTitleContainerStyle: {
            width: (bp === 'sm') ? width * 0.25 : width * 0.18
        },
        downloadTextStyle: {
            // width:(bp==='sm')? 240:300,
            fontSize: 16,
            lineSpacing: 2,
            textAlign: 'center',
            color: theme?.palette?.primaryDark,
            paddingTop: 5,
            paddingBottom: 5
        },
        buttonContianerStyle: {
            paddingTop: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        cardStyle: {
            backgroundColor: theme?.palette?.white,
            marginTop: 5,
            marginBottom: 5
        },
        companyCardStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        credTitleStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            paddingLeft: 10,
        },
        companyTitleStyle: {
            fontSize: 16,
            fontWeight: 'bold',
            paddingLeft: 10,
        },
        credDescStyle: {
            fontSize: 12,
            paddingLeft: 10,
        },
        shareTitleStyle: {
            fontSize: 16,
            paddingTop: 10,
            paddingBottom: 10
        },
        loginTitleStyle: {
            fontSize: 16,
            paddingTop: 10,
            paddingBottom: 10
        },
        backgroundStyle: {
            alignItems: 'center',
            // backgroundImage: 'url(https://charity-test.s3.ap-south-1.amazonaws.com/a0368c5d-e919-4939-a405-7658f58af958-web-bg.png)',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            // backgroundColor: '#88a9b6', 
            backgroundImage: 'linear-gradient(to right, #9bb6c2, #7298a7)',
            height: '100vh'
        }
    });

    const _getAppDownloadButton = () => {
        let _appLinks = BP?.appDownload?.links
        return (
            <View style={StyleSheet.flatten([styles.buttonContianerStyle])}>
                {Array.isArray(_appLinks) && _appLinks.map(item => {
                    return (
                        <a href={item?.url} target="_blank">
                            <AvatarV
                                source={{ uri: item?.icon }}
                                avatarStyle={{ resizeMode: 'contain' }}
                                containerStyle={{ width: 120 }}
                            />
                        </a>
                    )
                })}
            </View>
        )
    }

    const onFooterNavSelect = (eventKey: string) => {
        if (eventKey === 'terms') {
            history.push('/termsandcondition');
        }
        if (eventKey === 'privacy') {
            history.push('/privacy');
        }
    }

    const renderOrgProfile = () => {
        if (props?.logo && props?.nop) {
            return (
                <View style={StyleSheet.flatten([styles.companyCardStyle])}>
                    <AvatarV
                        source={{ uri: props?.logo }}
                        avatarStyle={{ resizeMode: 'contain' }}
                    />
                    <Text style={StyleSheet.flatten([styles.companyTitleStyle])}>{props?.nop}</Text>
                </View>
            )
        }

    }

    const renderFooterText = () => {
        let _text: any = BP?.footerText;
        if (_text) {
            _text = fillTemplate(_text, {
                redirect_uri: props.redirect_uri,
            });
        }
        return (
            <View>
                <Text style={{ textAlign: 'center' }}>{_text}</Text>
            </View>
        )
    }

    return (
        <FBGridV justify={"center"} align={"middle"} style={StyleSheet.flatten([styles.backgroundStyle])} >
            <FBItemV colspan={(bp === 'sm') ? 18 : 12}>
                <Panel shaded style={StyleSheet.flatten([styles.paperStyle])}>
                    <View style={StyleSheet.flatten([styles.containerStyle])}>
                        <View style={StyleSheet.flatten([styles.qrContainerStyle])}>
                            <Text style={StyleSheet.flatten([styles.titleStyle])}>
                                {BP?.title}
                            </Text>
                            <QRCodeGeneratorV
                                containerStyle={StyleSheet.flatten([styles.qrStyle])}
                                size={(bp === 'sm') ? 220 : 300}
                                value={props?.qrValue}
                                logo={BP?.qrLogoIcon}
                                logoBorderRadius={50}
                            />
                            <Text style={StyleSheet.flatten([styles.downloadTextStyle])}>
                                {BP?.downloadAppText}
                            </Text>
                            {_getAppDownloadButton()}
                        </View>
                        <View style={StyleSheet.flatten([styles.rightContainerStyle])}>
                            <View>
                                <Text style={StyleSheet.flatten([styles.loginTitleStyle])}>{BP.loginTitle}</Text>
                            </View>
                            <Panel shaded style={StyleSheet.flatten(styles.cardStyle)}>
                                {renderOrgProfile()}
                            </Panel>
                            <Divider />
                            {renderFooterText()}
                            <View>
                                <Text style={{ textAlign: 'center' }}><a href={BP?.footerURL} target="_blank">{BP?.footerURL}</a></Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ justifyContent: 'center', margin: '0 auto', display: 'block', textAlign: 'center' }}>
                        <Divider />
                        <NavV
                            items={BP?.footerNav}
                            appearance={'default'}
                            onSelect={onFooterNavSelect}

                            style={{ textAlign: 'center' }}
                        />
                        <Text style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <Icon
                                name={"copyright"}
                                type={"fontawesome"}
                            /> {moment().year()}{" "}
                            {BP?.copyRightText?.company} {"All rights reserved."}
                            {BP?.copyRightText?.poweredBy ? (
                                <Text>
                                    {' powered by'}{' '}
                                    <a
                                        href={BP?.copyRightText?.poweredByURL}
                                        target="_blank"
                                    >
                                        {' '}
                                        {BP?.copyRightText?.poweredBy}
                                    </a>
                                </Text>
                            ) : null}
                        </Text>
                    </View>
                </Panel>

            </FBItemV>
        </FBGridV>
    )
}