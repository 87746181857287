import React from 'react';
import config from '../../config/default-blueprint.json';
import {Text, View, StyleSheet} from 'react-native';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {FooterDetail} from './components/Footer';
import { useHistory, useLocation } from 'react-router-dom';
import {Header} from './components/Header';
const BP = config?.privacy;
interface Iprops {
    termsData?: any[];
}

export const PrivacyPolicy = (props: Iprops) => {
    const history = useHistory();
    const styles = {
        paragraphStyle: {
            display:'block',
            padding:10,
            textAlign:'justify'
        },
        titleStyle:{
            fontSize:16,
            fontWeight:600,
            display:'block',
            padding:5
        },
        headingStyle: {
            display:'block',
            fontSize: 20,
            fontWeight:700,
            textAlign:'center',
            padding:10

        }
    };

    let _termsData:any[] = [];
    let _data = props?.termsData ? props?.termsData : BP?.data;

    Array.isArray(_data) && _data.map(item=>{
        if(item.title){
            _termsData.push(
                <Text style={StyleSheet.flatten([styles.titleStyle])}>
                    {item?.title}
                </Text>
            )
        }
        if(item.p){
            _termsData.push(
                <Text style={StyleSheet.flatten([styles.paragraphStyle])}>
                    {item?.p}
                </Text>
            )
        }
        if(item.ul){
            _termsData.push(
              <ul>
                  {
                      Array.isArray(item?.ul) && item?.ul.map(ele=>{
                          if(ele?.link){
                              return(
                                  <li style={{padding:5}}>
                                      {ele?.link?.p}{" "}<a href={ele?.link?.href} target={"_blank"}>{ele?.link?.a}</a>
                                  </li>
                              )
                          }
                          else {
                            return <li style={{padding:5}}>{ele.li}</li>;
                          }
                        

                    })
                  }
              </ul>  
            )
            
        }
        if(item.ol){
            _termsData.push(
              <ol>
                  {
                      Array.isArray(item?.ol) && item?.ol.map(ele=>{
                        return <li style={{padding:5}}>{ele.li}</li>;
                    })
                  }
              </ol>  
            )
            
        }
        if(item.link){
            _termsData.push(
                <Text style={StyleSheet.flatten([styles.paragraphStyle])}>
                    {item?.link?.p}{" "}<a href={item?.link?.href} target={"_blank"}>{item?.link?.a}</a>
                </Text>
            )
        }
    });

    const onFooterNavSelect= (eventKey: string) =>{
        if(eventKey === 'terms'){
            history.push('/termsandcondition');
            window.scrollTo(0,0);
        }
        if(eventKey === 'privacy'){
            history.push('/privacy');
            window.scrollTo(0,0)
        }
        if(eventKey === 'aboutus'){
            history.push('/hello')
        }
    }

    return (
        <FBGridV justify={'center'}>
            <FBItemV colspan={24}>
                <Header 
                headerLogo={BP?.header?.logo}
                />
            </FBItemV>
            <FBItemV colspan={22}>
                <Text style={StyleSheet.flatten([styles.headingStyle])}>{BP?.title}</Text>
            </FBItemV>
            <FBItemV colspan={22}>
                {_termsData}
            </FBItemV>
            <FBItemV colspan={24}>
                <FooterDetail 
                onFooterNavSelect={onFooterNavSelect}
                />
            </FBItemV>
        </FBGridV>
    )
}
