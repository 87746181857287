import React, { useEffect, useState} from 'react';
import {
  useParams
} from "react-router-dom";
//@ts-ignore
import Request from 'axios-request-handler';
import config from '../../config/default.config.json';
import { HttpServer } from '../../servers/HttpServer';
const { getJoinNetworkDetailUrl, getPresentationStatusUrl } = config?.servers?.http;
const http = new HttpServer();



export const usePasswordless = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [networkDetails, setNetworkDetails] = useState<any>();
    const [networkError, setNetworkError] = useState<any>();

        var qs: any = (function(a) {
        if (a == "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i)
        {
            var p=a[i].split('=', 2);
            if (p.length == 1)
                b[p[0]] = "";
            else
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    })(window.location.search.substr(1).split('&'));
    useEffect(() => {
        setLoading(true);
        http.post(getJoinNetworkDetailUrl, {
            client_id: qs?.client_id,
            tag: qs?.tag,
        })
            .then(res => {
                window.history.replaceState(null, null, window.location.pathname);
                if (res?.data) {
                    setNetworkDetails(res?.data);
                }
                setLoading(false);
                const isUserActed = new Request(`${getPresentationStatusUrl}/${qs?.tag}`);
                isUserActed.poll(5000).get((response: any) => {
                //callback function that executes in every response
                //if return false the interval will discontinue
                    if (!response.data || response.data.status !== 'pending') {
                        return false;
                    }
                })
            })
            .catch(err => {
                window.history.replaceState(null, null, window.location.pathname);
                console.log('error in retrieving joining network', err);
                setLoading(false);
                setNetworkError(err?.message);
            });
    }, []);

    return {
        queryParams: qs,
        loading: loading,
        networkDetails: networkDetails,
        networkError,
    }
}