import React, { useState, useEffect } from 'react';
export const useDeeplink = () => {
    let timeout: any;
    let settings: any = {};
    let defaults: any = {
        iOS: {},
        android: {},
        androidDisabled: false,
        fallback: true,
        fallbackToWeb: false,
        delay: 1000,
        delta: 500
    }
    const extend = function(defaults: any, options: any) {
        const extended: any = {};
        for(var key in defaults) {
            extended[key] = defaults[key];
        };
        for(var key in options) {
            extended[key] = options[key];
        };
        return extended;
    };

    const getStoreURLiOS = function() {
        var baseurl = "itms-apps://itunes.apple.com/app/";
        var name = settings.iOS.appName;
        var id = settings.iOS.appId; 
        return (id && name) ? (baseurl + name + "/id" + id + "?mt=8") : null;
    }

    const getStoreURLAndroid = function() {
       // var baseurl = "market://details?id=";
        var baseurl = "https://play.google.com/store/apps/details?id=";
        var id = settings.android.appId;
        return id ? (baseurl + id) : null;        
    }

    const getStoreLink = function() {
        let linkmap: any = {
            "ios": settings.iOS.storeUrl || getStoreURLiOS(),
            "android": settings.android.storeUrl || getStoreURLAndroid()
        }

        return linkmap[settings.platform];
    }

    const getWebLink = function() {
        let linkmap: any = {
            "ios": settings.iOS.fallbackWebUrl || location.href,
            "android": settings.android.fallbackWebUrl || location.href
        }

        return linkmap[settings.platform];
    }

    const isAndroid = function() {
        return navigator.userAgent.match('Android');
    }

    const isIOS = function() {
        return navigator.userAgent.match('iPad') || 
               navigator.userAgent.match('iPhone') || 
               navigator.userAgent.match('iPod');
    }

    const isMobile = function() {
        return isAndroid() || isIOS();
    }

    const openFallback = function(ts: any) {
        return function() {
            var link = (settings.fallbackToWeb) ?  getWebLink() : getStoreLink();
            var wait = settings.delay + settings.delta;
            if (typeof link === "string" && (Date.now() - ts) < wait) {
                window.location.href = link;
            }
        }
    }

    const setup = function(options: any) {
        settings = extend(defaults, options);

        if (isAndroid()) settings.platform = "android";
        if (isIOS()) settings.platform = "ios";
    }

    const open = function(uri: any) {
        if (!isMobile()) {
            return false;
        }

        if (isAndroid() && settings.androidDisabled) {
            return;
        }

        if (isAndroid() && !navigator.userAgent.match(/Firefox/)) {
            var matches = uri.match(/([^:]+):\/\/(.+)$/i);
            uri = "intent://" + matches[2] + "#Intent;scheme=" + matches[1];
            uri += ";package=" + settings.android.appId + ";end";
        }

        if (settings.fallback|| settings.fallbackToWeb) {
            timeout = setTimeout(openFallback(Date.now()), settings.delay);
        }
        
        let iframe: any = document.createElement("iframe");
        iframe.onload = function() {
            clearTimeout(timeout);
            iframe.parentNode.removeChild(iframe);
            window.location.href = uri;
        };

        iframe.src = uri;
        iframe.setAttribute("style", "display:none;");
        document.body.appendChild(iframe);
        
        return true;
    }

    return {
        setup: setup,
        open: open,
        timeout: timeout,
    };
    
}
