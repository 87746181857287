import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Eight({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1090.667}
            height={1706.667}
            viewBox="0 0 818 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M362 40.1c-92.7 7.4-167.7 38.7-223.8 93.4-23.9 23.2-40.4 45.6-53.8 73-34.2 70-36.3 159.5-5.7 237 29.1 73.6 86.9 141.9 171.5 203l13.8 9.9-24.8 12.6C88.5 745.9 23.5 836.6 27.3 964.4c2.3 78.8 32.6 142.1 90.7 189.7 56 45.9 128 72.2 220 80.4 9.6.8 29.9 1.8 45 2.2 120 2.6 217.5-23.7 290.4-78.6 67.5-50.8 105.1-116.4 115.2-200.6 2-17.2 2.3-56.8.5-74-10.5-100.1-59.6-183.3-155.3-263.4-19.6-16.3-49.2-38.3-70.1-52.1-9.7-6.3-17.6-11.7-17.6-12-.1-.3 6.1-3.6 13.7-7.4 17.9-8.9 38.5-20.7 54.7-31.3 80.6-52.9 123.1-113.2 135-191.3 2.2-14.6 3.1-46.8 1.6-61.1-5.3-50-25.4-92.2-61.2-127.9C633.4 80.5 550.1 47.1 448 40c-16.9-1.1-70.8-1.1-86 .1zm76.3 87.5c47.4 7.7 85.5 33.3 110.1 74.1 17.2 28.6 25.9 67.5 24.3 108.3-2.6 62.9-25.3 116.9-72.1 171.5-7.9 9.3-23.8 25.5-25 25.5-1.5 0-30.2-17.7-51.1-31.5C339.3 419 291.2 367.1 273.1 312c-13.5-41.2-11.4-85.1 5.7-118.5 19.9-38.7 59.5-62.9 110.4-67.4 13-1.2 37.1-.4 49.1 1.5zM359 723.4C426.5 763.8 480.4 805 513.8 842c43.9 48.5 65 100.9 62.9 156.5-1.6 42.3-15.1 75.3-42 102.7-23.8 24.4-53.3 39.5-89.7 45.9-14.7 2.7-46.1 3.6-62.1 2-41.1-4.3-74.2-17.6-102.6-41.4-37.9-31.9-60.8-77.8-66.4-133.1-.7-7.4-1-19.9-.6-33.5.8-30.2 3.9-48.6 13.2-76.7 15.8-48 43.7-91.9 90.9-142.7 12.7-13.6 13.9-14.6 15.9-13.6 1.2.7 12.8 7.5 25.7 15.3z" />
        </svg>
    )
}
