import styled from "styled-components";
import { fontFamily, headingSizes, themeColors } from "../../../settings";

export interface IButtonProps {
  h?: number;
  color: any;
  bg?: string;
  nospaces?: any;
}

const styles = `
  border: 0;
  border-radius: 4px;
  outline: 0;
  color: white;
  padding: .5em 1em;
  font-family: ${fontFamily};
  margin-left: .25em;
  margin-right: .25em;
  cursor: pointer;
  
  &:hover {
    opacity: .9;
  }
`;
const Button = styled.button`${styles}`;
const Link = styled.a`${styles}`;

const StyledButton = styled(Button)`
  font-size: ${(props: IButtonProps) => (props.h ? headingSizes[props.h - 1] : "inherit")};
  background-color: ${(props: IButtonProps) => themeColors[props.color] || themeColors.dark};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${(props: IButtonProps) => (props.h ? headingSizes[props.h - 1] : "inherit")};
  background: ${(props: IButtonProps) => (props.bg ? themeColors[props.bg] : "none")};
  color: ${(props: IButtonProps) =>
    props.bg
      ? "white"
      : props.color ? themeColors[props.color] : themeColors.black};
  ${(props: IButtonProps) =>
    props.nospaces &&
    `
    display: inline-block;
    padding: 0;
    margin: 0;
  `}
`;

export { StyledButton as Button };
export { StyledLink as Link };
