//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { SectionV, BoxV, RowV, HeadingV } from '../../MobileTour/widgets';
import useCountDown from 'react-countdown-hook';


const initialTime = 60 * 1000;
const interval = 1000

export const CountDown = ({
    seconds,
}: any) => {
    const [timeLeft, { start }] = useCountDown(seconds, interval);
    useEffect(() => {
        start();
    }, [])
    return (
        <div>
            <SectionV center>
                <BoxV center>
                    <View style={StyleSheet.flatten([styles.textContainerStyle])}>
                        <HeadingV h={3} color={'light'} fontWeight={800}>
                            {`You will be redirecting to Klefki Home page in ${(timeLeft / 1000)} second(s)`}
                        </HeadingV>
                    </View>

                </BoxV>
            </SectionV>
        </div>

    )
}

const styles = StyleSheet.create({
    textContainerStyle: {
        marginTop: '50%',
    }
})