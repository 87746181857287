import React from 'react';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';

export const VideoV = (props: any) => {
    return (
        <Player
        >
            <source src={props.uri} />
        </Player>
    )
}