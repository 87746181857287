import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Four({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1170.667}
            height={1706.667}
            viewBox="0 0 878 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M485.4 48.3C468.5 76.4 109.6 675.6 56.8 763.6L43 786.7v122.9l2.8 2.7 2.8 2.7H416.3l-.6 53.2c-.5 55.6-1.4 69.4-5.6 88.5-5.8 26.8-14.9 41.3-30.9 49.7-20.6 10.7-61.8 19.3-106.2 22.1-5.2.3-11 .8-12.7 1.1l-3.3.5V1213.4l2.7 2.8 2.7 2.8h549.2l2.7-2.8 2.7-2.8V1130.1l-3.2-.5c-1.8-.3-7.6-.8-12.8-1.1-24.3-1.4-52.8-6.4-69.9-12.1-21.3-7.1-31.4-14.8-38.6-29.8-11.2-23.2-14.5-51.7-14.5-127.4V915h133.4l2.8-2.7 2.8-2.7V804.3l-2.3-2.1-2.3-2.2-67-.2-66.9-.3-.3-380.4-.2-380.5-2.7-2.8-2.7-2.8H494.5l-9.1 15.3zm-33.1 209.9c-1 6.5-17.4 228.6-23.3 317.3-4.8 73-13 211.1-13 221.2v3.3H269.5c-80.6 0-146.5-.3-146.5-.6 0-1.1 328.5-543.4 329.1-543.4.3 0 .5 1 .2 2.2z" />
        </svg>
    )
}

