//@ts-nocheck
import React from 'react';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
    Avatar,
    Heading,
    Steps,
    Arrow,
} from './Components';
import {
    SectionV
} from './widgets';

import config from './config/default.blueprint.json';

const bp = config;

import { useMobileTour } from './provider';

interface IMobileTourProps {
    nop: string;
    type: string;
    deepLinkingUrl: string;
    joinNetworkDemoUrl?: string;
}

export const MobileTour = ({
    nop,
    type,
    deepLinkingUrl,
    joinNetworkDemoUrl,
}: IMobileTourProps) => {
    const {
        isTourOpen,
        isShowingMore,
        toggleShowMore,
        closeTour,
        openTour,
        getTourConfig,
    } = useMobileTour();
    const accentColor = "#5987AF";

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);
    const renderTour = () => {
        const _tour = getTourConfig({
            nop: nop,
            type: type,
            joinNetworkDemoUrl: joinNetworkDemoUrl
        })
        return (
            <Tour
                onRequestClose={closeTour}
                steps={_tour}
                isOpen={isTourOpen}
                maskClassName="mask"
                className="helper"
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                rounded={10}
                accentColor={accentColor}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
        )
    }

    const renderBody = () => {
        return (
            <div>
                <SectionV center>
                    <Avatar />
                    <Heading
                        nop={nop}
                        type={type}
                    />
                    <Arrow />
                </SectionV>
                <Steps
                    nop={nop}
                    type={type}
                    deepLinkingUrl={deepLinkingUrl}
                    joinNetworkDemoUrl={joinNetworkDemoUrl}
                />
            </div>
        )
    }
    return (
        <div>
            {renderBody()}
            {renderTour()}
        </div>
    )
}