import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Nine({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1073.333}
            height={1706.667}
            viewBox="0 0 805 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M363 39.1c-96.8 5.5-174.7 38.2-235 98.8C59.4 206.8 25.3 300.3 25.3 419c0 115.7 34.4 208 103.1 276.5 31.8 31.7 65 52.6 105.6 66.6 61.9 21.4 135.9 21.3 196.5-.2 21.6-7.7 57.2-27.1 73.8-40.1l3.7-3v30.9c0 151.2-22.7 262.5-66.9 328.9-33.2 49.8-77.4 72.1-133.9 67.5-38.2-3.1-62.1-17-62.2-36.2 0-9.2 9.4-18.9 36.4-37.2 8.3-5.6 18.4-13.3 22.4-17.1 16.6-15.7 26-36.9 28-62.7 4.5-57.1-25.5-102.6-79.1-120.4-25.1-8.2-58.3-9.6-87.4-3.5-52 10.8-91.3 46.7-105.7 96.2-4.1 14.1-5.7 25.2-6.3 43.4-1.6 46.7 10.1 85.7 36.5 121.4 7.2 9.8 25.3 28.3 36.7 37.5 28.7 23.3 64.6 41 103 50.9 46.3 11.8 102.4 14.5 157.5 7.5 129.2-16.3 228.9-83.5 300.2-202.3 49-81.4 79.2-183.8 90.2-305.6 3.5-38.2 4.1-54.2 4-110.5 0-61.3-.9-81.2-6-126.5-18.7-169.3-77.5-296.6-171.9-372.5C539.5 57 458.8 33.7 363 39.1zm60.7 92.4c37 7.2 60.2 35.6 71.8 87.8 8 36.4 11.7 75 13.7 144.1 1.2 42.8.2 106.2-2.2 140.1-6.6 91.8-20.5 136.9-49.5 160.2-16.1 12.9-34.2 18.1-59.3 17-47.5-2-73.8-29.9-86.2-91.5-9-44.8-12.4-95.6-12.4-184.2 0-89.1 3.5-141.4 12.5-185.4 10.4-51.3 30.7-78 65.9-87.1 12.9-3.4 31.4-3.7 45.7-1z" />
        </svg>
    )
}
