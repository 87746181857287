//@ts-ignore
import React from "react";
import { Linking } from 'react-native';
import PropTypes from "prop-types";
import { Button } from 'react-native-elements';


export const OpenApp = ({ options, href, android, ios, blank, children, onDeepLinkClick, ...rest }: any) => {
  let timeout: any;
  let defaults: any = {
    iOS: {},
    android: {},
    androidDisabled: false,
    fallback: true,
    fallbackToWeb: true,
    delay: 1000,
    delta: 500
  }
  let settings: any = {};

  const extend = function (defaults: any, options: any) {
    const extended: any = {};
    for (var key in defaults) {
      extended[key] = defaults[key];
    };
    for (var key in options) {
      extended[key] = options[key];
    };
    return extended;
  };
  settings = extend(defaults, options);

  // from: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
  const getMobileOperatingSystem = () => {
    const userAgent =
      window.navigator.userAgent || window.navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  const openFallback = function (ts: any) {
    return function () {
      let href: any;
      if (getMobileOperatingSystem() === "iOS") {
        href = getStoreURLiOS();
      }
      else if (getMobileOperatingSystem() === "Android") {
        href = getStoreURLAndroid();
      }
      let link: any = (settings.fallbackToWeb) ? href : null;
      var wait = settings.delay + settings.delta;
      if (typeof link === "string" && (Date.now() - ts) < wait) {
        //@ts-ignore
        window.location = link;
      }
    }
  }

  const preventPopup = () => {
    clearTimeout(timeout);
    timeout = null;
    window.removeEventListener('pagehide', preventPopup);
  }

  const onClick = async (android: any, iOS: any, href: any, blank: any) => {
    try {
      if (iOS && getMobileOperatingSystem() === "iOS") {
        try {
          const canOpen = await Linking.canOpenURL(iOS);
          if (canOpen) {
            $('<iframe />')
              .attr('src', iOS)
              .attr('style', 'display:none;')
              .appendTo('body');

            timeout = setTimeout(openFallback(Date.now()), 500);
            window.addEventListener('pagehide', preventPopup);
            //  window.addEventListener('pagehide', preventPopup);
          }
          else {
            fallback(href, blank);
          }
        }
        catch (err) {
          fallback(href, blank);
        }

      } else if (android && getMobileOperatingSystem() === "Android") {
        try {
          const canOpen = await Linking.canOpenURL(android);
          if (canOpen) {
            let timeout: any = setTimeout(openFallback(Date.now()), settings.delay);
            let iframe: any = document.createElement("iframe");
            iframe.onload = function () {
              clearTimeout(timeout);
              timeout = null;
              iframe.parentNode.removeChild(iframe);
              window.location = android;
            };

            iframe.src = android;
            iframe.setAttribute("style", "display:none;");
            document.body.appendChild(iframe);
            // window.location = iOS;
          }
          else {
            fallback(href, blank);
          }
        }
        catch (err) {
          fallback(href, blank);
        }
      } else {
        fallback(href, blank);
      }
    } catch (err) {
      console.log('fallback', err);
      // Reload window to have correct document
      window.location.reload(true);
      fallback(href, blank);
    }
  };

  const fallback = (href: any, blank: any) => {
    let _href: any = href;
    if (getMobileOperatingSystem() === "iOS") {
      _href = getStoreURLiOS();

    }
    else if (getMobileOperatingSystem() === "Android") {
      _href = getStoreURLAndroid();
    }
    // Open in same or different window the original URL
    if (blank) window.open(_href, "_blank");
    else window.location = _href;
  };

  const getStoreURLiOS = function () {
    var baseurl = "itms-apps://itunes.apple.com/app/";
    var name = settings.iOS.appName;
    var id = settings.iOS.appId;
    return (id && name) ? (baseurl + name + "/id" + id + "?mt=8") : null;
  }

  const getStoreURLAndroid = function () {
    var baseurl = "market://details?id=";
    var id = settings.android.appId;
    return id ? (baseurl + id) : null;
  }

  const getStoreLink = function (platform: "ios" | "android") {
    let linkmap: any = {
      "ios": settings.iOS.storeUrl || getStoreURLiOS(),
      "android": settings.android.storeUrl || getStoreURLAndroid()
    }

    return linkmap[platform];
  }

  const androidDeepLink = android || href;
  const iOSDeepLink = ios || href;

  // return (
  //   <a
  //     href={href}
  //     onClick={e => {
  //       e.preventDefault();
  //       onClick(androidDeepLink, iOSDeepLink, href, blank);
  //     }}
  //     {...rest}
  //   >
  //     {children}
  //   </a>
  // );
  return (
    <Button
      onPress={e => {
        onClick(androidDeepLink, iOSDeepLink, href, blank);
      }}
      title={'klefki'}
      buttonStyle={{ backgroundColor: 'tomato' }}
    />
  )
};

OpenApp.propTypes = {
  href: PropTypes.string,
  android: PropTypes.string,
  ios: PropTypes.string,
  blank: PropTypes.bool,
  onDeepLinkClick: PropTypes.func,
  options: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
OpenApp.defaultProps = {
  //  href: "",
  blank: false,
  options: {
  },
  onDeepLinkClick: () => { },
};
