import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Seven({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1100}
            height={1706.667}
            viewBox="0 0 825 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M72 47.2c0 .7-7.7 91.7-17.1 202.1L37.7 450.1l2.9 3 2.8 2.9h70.1l1.3-6.8c10.1-53.7 27.2-104.9 43.7-130.2 16.6-25.6 41.8-38 91.3-44.8 10.2-1.3 33.3-1.6 182.5-1.9l170.8-.4-3.7 5.8C497.3 436.9 409.1 589.3 350.5 707.5 298.6 812.3 265.6 895.9 248.2 967c-10.3 41.6-14.2 70.8-14.2 104.6 0 37.4 5.4 64.2 18.1 89.7 25 50.4 73 76.7 139.8 76.7 94.6 0 149.1-48.9 162.5-145.7 4.4-31.7 4.2-58.7-.9-140.8-1.3-21.5-3-48.2-3.7-59.5-1.7-27.4-1.7-130.6 0-153.5 3.4-46.3 7.4-80.7 13.7-118C593.3 443.3 665.2 283.4 779.4 141l3.6-4.5V51.4l-2.8-2.7-2.8-2.7H424.7C143.2 46 72 46.3 72 47.2z" />
        </svg>
    )
}
