import React from 'react';
import { View, StyleSheet } from 'react-native';
import { HeadingV, RowV } from '../widgets';
import config from '../config/default.blueprint.json';
import { fillTemplate } from '../../../utils/fillTemplate';


const titleHeading = config.TitleHeading;
const subtitleHeading = config.SubTitleHeading;
const guidanceHeading = config.GuidanceHeading;

interface IHeadingProps {
    nop: string;
    type?: string;
}

export const Heading = ({
    nop,
    type
}: IHeadingProps) => {
    const renderScrollContent = () => {
        const _title = fillTemplate(guidanceHeading?.title, {
            nop: nop,
            type: type,
        })
        return (
            <View style={StyleSheet.flatten([styles.guidanceContainer])}>
                <RowV
                >
                    <HeadingV
                        {...{ ...guidanceHeading?.compProps }}
                    >
                        {_title}
                    </HeadingV>
                </RowV>
            </View>
        )
    }
    return (
        <div>
            <HeadingV
                {...{ ...titleHeading?.compProps }}
            >
                {titleHeading?.title}
            </HeadingV>
            <View style={StyleSheet.flatten([styles.subtitleContainer])}>
                <RowV>
                    <HeadingV
                        {...{ ...subtitleHeading?.compProps }}
                    >
                        {subtitleHeading?.title}
                    </HeadingV>
                </RowV>
            </View>
            {renderScrollContent()}
        </div >
    )
}

const styles = StyleSheet.create({
    subtitleContainer: {
        "paddingTop": 20,
        "flex": 1
    },
    guidanceContainer: {
        "paddingTop": 20,
        "flex": 1
    }
})

Heading.defaultProps = {
    type: 'Credentials'
} as Partial<IHeadingProps>