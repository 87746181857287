//@ts-nocheck
import React from 'react';
import { Avatar } from 'react-native-elements';
import { View } from 'react-native';
import { Shake } from "reshake";
export const AvatarComponent = (props: any) => {
    return (
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Shake dur={10000} int={4} h={0} v={5} r={3} max={70} fixed>
                <Avatar
                    {...{ ...props }}
                />
            </Shake>
        </View>
    )
}