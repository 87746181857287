import React from 'react';
import MobileStoreButton from 'react-mobile-store-button';
import { Shake } from "reshake";
import {
    isAndroid,
    isIOS,
    isMobileOnly,
    isBrowser,
} from "react-device-detect";
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { Button, Icon } from 'react-native-elements';
import {
    LinkV, RowV, BouncyContainerV,
    BoxV, SectionV, ImageV,
    TextV, HeadingV, VideoV,
} from '../widgets';
import { One, Two, Three, Four, Five, Six, Seven, Eight, Nine, Zero } from '../svgs';

import config from '../config/default.blueprint.json';
import { fillTemplate } from '../../../utils/fillTemplate';
import { Video } from 'expo-av';

const is = require('is_js');

const stepsConfig = config.Steps;

interface IStepsProps {
    nop?: string;
    type?: string;
    deepLinkingUrl?: string;
    joinNetworkDemoUrl?: string;
}

export const Steps = ({
    nop,
    type,
    deepLinkingUrl,
    joinNetworkDemoUrl,
}: IStepsProps) => {

    const _renderNumber = (step: any) => {
        if (step?.index === '1') {
            return <One fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '2') {
            return <Two fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '3') {
            return <Three fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '4') {
            return <Four fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '5') {
            return <Five fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '6') {
            return <Six fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '7') {
            return <Seven fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '8') {
            return <Eight fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '9') {
            return <Nine fill={'#1a4677'} width={20} height={40} />
        }
        if (step?.index === '0') {
            return <Zero fill={'#1a4677'} width={20} height={40} />
        }
    }
    const renderStep = (step: any) => {
        if (step?.comp?.type === "video" && !joinNetworkDemoUrl) {
            return;
        }
        return (
            <BoxV center width={'100%'}>
                <Shake dur={10000} int={4} h={0} v={5} r={3} max={70} fixed>
                    {_renderNumber(step)}
                </Shake>
            </BoxV>
        )
    }
    const renderStepTitle = (step: any) => {
        if (step?.comp?.type === "video" && !joinNetworkDemoUrl) {
            return;
        }
        let _title: any = step?.heading?.title;
        if (_title) {
            _title = fillTemplate(step?.heading?.title, {
                nop: nop,
                type: type,
            })
        }
        return (
            <HeadingV
                {...{ ...step?.heading?.compProps }}
            >
                {_title}
            </HeadingV>
        )
    }
    const renderDescription = (step: any) => {
        if (step?.comp?.type === "video" && !joinNetworkDemoUrl) {
            return;
        }
        let _description: any = step?.heading?.description;
        if (_description) {
            _description = fillTemplate(step?.heading?.description, {
                nop: nop,
                type: type,
            })
        }
        return (
            <View style={StyleSheet.flatten([styles.descriptionContainer])}>
                <TextV>
                    {_description}
                </TextV>
            </View>

        )
    }
    const renderStore = (step: any) => {
        if (step?.comp?.type === "video" && !joinNetworkDemoUrl) {
            return;
        }
        if (step?.store && is.not.empty(step?.store)) {
            if (isIOS) {
                return (
                    <View style={StyleSheet.flatten([styles.storeContainer])}>
                        <MobileStoreButton
                            store={"ios"}
                            url={step?.store?.ios?.href}
                            width={'40%'}
                            data-tut={step["data-tut"]}
                        />
                    </View>
                )
            }
            if (isAndroid) {
                return (
                    <View style={StyleSheet.flatten([styles.storeContainer])}>
                        <MobileStoreButton
                            store={"android"}
                            url={step?.store?.android?.href}
                            width={'60%'}
                            data-tut={step["data-tut"]}
                        />
                    </View>
                )
            }
        }
    }

    const renderComp = (step: any) => {
        if (step?.comp?.type === "video" && !joinNetworkDemoUrl) {
            return;
        }
        const _handleButtonPress = (url: string) => {
            console.log('ButtonPress =>', url);
            if (url) {
                window.location.replace(url);
            }
        }
        if (step?.comp) {
            let _title: any = step?.comp?.props?.title;
            if (_title) {
                _title = fillTemplate(step?.comp?.props?.title, {
                    nop: nop,
                    type: type,
                })
            }
            switch (step.comp.type) {
                case 'button':
                    let _onPressUrl: any = step.comp.onPressUrl;
                    if (_onPressUrl) {
                        _onPressUrl = fillTemplate(_onPressUrl, {
                            joinNetworkUrl: deepLinkingUrl,
                        })
                    }
                    return (
                        <Button
                            {...{ ...step.comp.props }}
                            title={_title}
                            data-tut={step["data-tut"]}
                            onPress={_handleButtonPress.bind(null, _onPressUrl)}
                        />
                    )
                case 'video':
                    let _url: any = step.comp?.props?.source?.uri;
                    if (_url) {
                        _url = fillTemplate(_url, {
                            joinNetworkDemoUrl: joinNetworkDemoUrl,
                        });
                    }
                    if (_url) {
                        return (
                            <View
                                style={StyleSheet.flatten([styles.videoContainer])}
                                data-tut={step["data-tut"]}
                            >
                                <VideoV
                                    {...{ ...step.comp.props }}
                                    uri={_url}
                                />
                            </View>

                        )
                    }

            }
        }
    }

    const renderArrow = (index: number) => {
        if (index !== stepsConfig?.length - 1) {
            return (
                <View style={StyleSheet.flatten([styles.arrowContainer])}>
                    <BouncyContainerV
                    >
                        <Icon
                            name={'angle-double-down'}
                            type={'font-awesome'}
                            size={30}
                            color={'#1a4677'}
                        />
                    </BouncyContainerV>
                </View>

            )
        }
    }

    const renderSteps = () => {
        const _comps: any = [];
        stepsConfig.forEach((step, index) => {
            _comps.push(
                <SectionV key={`step-klefki-${index}`}>
                    <BoxV center width={'100%'}>
                        {renderStep(step)}
                        {renderStepTitle(step)}
                        {renderDescription(step)}
                        {renderStore(step)}
                        {renderComp(step)}
                        {renderArrow(index)}
                    </BoxV>
                </SectionV>

            );
        })
        return _comps;
    }
    return (
        <>
            {renderSteps()}
        </>
    )
}

const styles = StyleSheet.create({
    descriptionContainer: {
        paddingTop: 10,
    },
    storeContainer: {
        paddingTop: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    videoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 15,
    },
    arrowContainer: {
        paddingTop: 40,
    }
})

Steps.defaultProps = {
    type: 'Credentials'
} as Partial<IStepsProps>