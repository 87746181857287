import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Five({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1052}
            height={1706.667}
            viewBox="0 0 789 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M685.5 27.6c-28.2 11.6-41.3 16.6-57 21.7-37.7 12.4-75.3 20.1-117.5 24.2-41.6 4.1-129.6 2.7-188.5-3.1-54.9-5.3-111.3-18.3-165.4-38.1-9.5-3.4-17.7-6.3-18.2-6.3s-2.4 1-4.3 2.2l-3.3 2.2-.7 25c-.8 29.3-2.3 73.4-3.1 89.1-6.6 133.3-17.7 246-36.6 369.5-3.4 22.6-14 84.9-16.8 99.5-1.5 7.6-1.7 10.3-.8 11.1 1.4 1.3 72.5 44.4 73.2 44.4.4 0 7.4-4.9 15.8-10.8 59.2-42.2 96.3-61.6 132.3-69.3 14.1-3 39.1-3.7 54.2-1.5 41.9 6.2 74.4 28.1 98 66.2 24.1 38.9 37.8 91.8 42.1 162.4 1.1 18.1.6 78.8-.9 97.5-6 78.7-21.6 134.6-49 175.7-28.8 43.4-69.3 64.8-122.5 65-24.4 0-42.8-3.8-60.7-12.8-14.5-7.2-24.2-15.5-27.8-23.7-1.8-4.1-1.9-5.1-.8-6.4.7-.9 12.8-11.2 26.8-22.8 31-25.8 35.7-31 44.5-49.8 9.3-19.8 13.1-40 12.2-65.7-1.3-37-13-63.7-39.2-88.8-19.8-19.1-40.9-30.1-69.5-36.4-9.3-2-13.2-2.2-33.5-2.2-22.6 0-23.2.1-35 3.2-14.2 3.8-32.5 12.4-43 20.4-24.6 18.6-43.4 47-52 78.4-4.6 16.5-5.7 25.1-6.2 47-.8 30.1 1.8 49.3 9.7 73.4 26.3 80.5 107.8 139.6 218 158.4 48.1 8.2 108.1 9.6 159.9 3.6 97.6-11.2 174.3-45 233.5-102.9 29-28.5 48.2-54.3 65.6-88.6 20.6-40.3 33.2-84.5 39.6-138.5 2.1-18.3 3-75.4 1.5-96-5.7-74.7-27.3-138.2-65.1-191-66.6-93.1-169.4-141-295.5-137.7-41.8 1.1-76.1 7.3-116.4 21.2-35 12.1-66.4 27.9-97.5 49.2l-10.8 7.4.5-4.3c.4-2.4 6.3-65.7 13.3-140.8 6.9-75.1 12.7-136.6 12.8-136.7.1-.1 3.8 1.2 8.2 2.9 32.1 12.5 61.8 15.4 148.9 14.5 73.6-.8 109.8-3.7 152-12.3 71.6-14.5 125.5-46.1 169.9-99.4 3.9-4.7 10.5-12.2 14.8-16.7 22.9-24.6 36.7-58.9 39.4-98l.7-10.1-3.8-6.9c-5-8.9-12.7-17-19-19.9-7.9-3.7-13.8-3.2-27 2.2z" />
        </svg>
    )
}
