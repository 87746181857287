import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Six({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1158.667}
            height={1706.667}
            viewBox="0 0 869 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M480.6 23.1c-18.8 1.2-43.6 4.5-63.4 8.5C311 52.9 223.8 112.3 157.5 208.5 76.7 325.9 37.8 491 43.1 694.5c4.4 168.9 39.4 299.4 106.6 397.2 68.6 100 161.5 152.4 278.3 157.2 150.7 6.1 267.6-50.1 338.2-162.7 30.7-48.8 49.3-108.8 56-180.7 1.7-18.3 1.7-77.7 0-95-10-101.1-43.4-177.1-104.6-238-49.4-49.2-106.9-76.1-178.6-83.6-16.7-1.7-53.6-1.5-70.4.6-49.8 5.9-88.2 20.8-130.1 50.4l-10 7.1-.3-32.8C327 392.6 343.5 285.6 374 216.5c26.9-60.9 63.5-95.3 113-106.1 12.3-2.7 50.7-2.7 63 0 27.8 6.1 44.5 18 47.4 33.8 1.6 9-6.9 18-36.4 38.3-11.2 7.7-23.2 16.7-26.5 20-23.1 22.6-33.2 61.3-26 99.1 6.6 34.7 29.5 64.2 62.7 81 28 14.1 63.6 18.5 100.8 12.3 32.2-5.4 57.4-18.1 79.6-40.3 22.2-22.3 34.6-46.7 40.5-80.1 1.7-9.5 2.1-16.1 2.2-32 0-21.6-1.4-34-5.9-52-18-71.4-78.1-128-163.5-153.8-39.5-12-91.9-16.9-144.3-13.6zM461 591c34.5 8.5 54.9 33.9 65.5 81.5 10.7 48.1 15.9 140.7 13.5 242-2 87.8-7.6 139.4-18.7 175-10.4 33.3-31.1 54.7-59.8 62-25.3 6.4-54.1 3.1-73.9-8.7-8.1-4.8-19.6-15.8-25.3-24.3-21.1-31.5-31.4-85.3-35.4-186-1.3-32.7-.7-124 1.1-150.5 5.2-80.9 14.3-125.4 31.1-153 7.4-12 20.9-25.3 31.4-30.7 8.8-4.5 18.8-7.8 28-9.3 9.7-1.5 32.7-.4 42.5 2z" />
        </svg>
    )
}