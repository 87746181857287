import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { Button, Icon } from 'react-native-elements';
import { BouncyContainerV } from '../widgets';

export const Arrow = () => {
    return (
        <View style={StyleSheet.flatten([styles.arrowContainer])}>
            <BouncyContainerV
            >
                <Icon
                    name={'angle-double-down'}
                    type={'font-awesome'}
                    size={30}
                    color={'#1a4677'}
                />
            </BouncyContainerV>
        </View>

    )
}

const styles = StyleSheet.create({
    descriptionContainer: {
        paddingTop: 10,
    },
    storeContainer: {
        paddingTop: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    videoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 15,
    },
    arrowContainer: {
        paddingTop: 60,
    }
})