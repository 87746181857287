import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import Iframe from 'react-iframe';
import config from '../config/default-blueprint.json';
import { OpenApp } from '../../../providers/openApp';
const AndroidUri: any = "klefki://monarch.klefki.io/holder/credential/tag/get?tag=d1bea7e5-9599-4be9-9f02-33f156420c19&nop=vlinder&vc=generic&nonce=d6d624ba-7712-42b3-93a9-ced08146fb46";
const IOSUri: any = "klefki://production.klefki.io/holder/credential/tag/get?tag=d1bea7e5-9599-4be9-9f02-33f156420c19&nop=vlinder&vc=generic&nonce=d6d624ba-7712-42b3-93a9-ced08146fb46";


export const DeepLinking = () => {
    return (
        <View style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#d2d2d2', flex: 1 }}>
            <OpenApp
                // options={{
                //     iOS: {
                //         appName: "trag",
                //         appId: "1536485775",
                //     },
                //     android: {
                //         appId: "io.vlinder.trag"
                //     }
                // }}
                android={AndroidUri}
                ios={IOSUri}
                href={"https://www.vlinder.io"}
            // onDeepLinkClick={handleDeepLinkingclick}
            >
                Join SkoolMentor
            </OpenApp>
        </View>
    )
}