import * as React from "react"

export function One(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1046.667}
            height={1706.667}
            viewBox="0 0 785 1280"
            {...props}
        >
            <path d="M449.5 50.2c-60.4 53.5-148.6 86.6-266 99.8-33.1 3.7-85.4 7-110.4 7H63l-.2 41.5-.3 41.6 3 2.9 3 2.9 73.5.4c62 .3 75.3.7 85 2.1 14.9 2.2 25.4 5 31.6 8.6 8.2 4.6 12.3 14.2 16.1 37.2 1.6 9.5 1.8 37.1 2.1 366.3.2 259.3 0 359.1-.8 367.5-4.4 44.6-17.7 71.4-41.6 83.8-27.9 14.4-66.3 20.2-135 20.2h-33l-2.7 2.8-2.7 2.8v77.8l2.7 2.8 2.7 2.8h677.2l2.7-2.8 2.7-2.8v-78l-2.7-2.6-2.8-2.6-39-.5c-41.5-.5-55.7-1.7-77.9-6.2-21.2-4.3-36.7-10.3-48-18.7-15.6-11.5-26-34.7-31.2-69.3-1.7-11.4-1.8-35-2.1-500.5L545 48.6l-2.7-2.8-2.7-2.8-41 .1h-41.1l-8 7.1z" />
        </svg>
    )
}
