import styled from "styled-components";
import { themeColors } from "../../../settings";

export default styled.footer`
  width: 100%;
  background-color: ${themeColors.light};
  text-align: center;
  padding-bottom: 3em;
  padding-top: 1em;
`;
