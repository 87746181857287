import * as React from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}

export function Zero({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1174.667}
            height={1706.667}
            viewBox="0 0 881 1280"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d="M424.8 30.1c-76.8 4.6-150.8 39-209.4 97.4-26.5 26.4-46.8 52.9-70.1 91.5C91.9 307.5 57.9 415.5 46 534.5c-6.1 61.2-6.6 130.6-1.4 191.5 13.4 158.3 65.1 296.8 148 396 44.1 52.9 108 93.8 171.7 110 31.7 8 55 10.6 89.2 9.7 38.9-1 68.5-6.5 103.5-19.3 12.9-4.7 43.2-19.8 56-27.9 87.2-55 160.6-163 198.4-291.8 21.2-72 31.8-144.9 34.6-236.8 4.4-145.3-20.6-280.7-72.5-392.9-28.6-61.9-70.5-123.6-106.2-156.6C610 63.7 541.5 34.8 462.5 30c-18.4-1.1-19-1.1-37.7.1zm45.7 92.4c23.4 6.9 40.2 21.9 52.8 47 18.6 37.2 26.8 100.4 31.8 247 .5 15.9 1.2 92.2 1.6 169.5.8 213.4-1.5 318.9-8.8 398.5-2.3 25.5-6 53.7-8.9 68.6-11.1 56.1-33.7 86.8-71.5 97-11.5 3.1-35.5 3.1-47 0-8.9-2.4-20.2-7.4-26.9-12-15.9-11-30.3-32.7-38.4-58.1-12.8-40-20.1-117.9-23.3-247-1.4-58.5-.7-398 1-437 5.1-121 12.3-180.9 26.1-215.2 12.4-31 31-50 56.7-57.8 11.2-3.4 15.8-3.9 31.3-3.6 12.1.3 16 .8 23.5 3.1z" />
        </svg>
    )
}
