import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { HeaderLG } from './Header.lg';
import { HeaderSM } from './Header.sm';

export interface IProps {
  headerLogo?:string;
}

export const Header = (props: IProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <HeaderSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    // if(bp === 'lg')
    return <HeaderLG {...props} />;
};
